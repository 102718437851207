import * as React from 'react';
import {Route, Routes} from 'react-router-dom';

const Authenticate = React.lazy(() => import('../../pages/Auth/Authenticate'));
const AuthenticateCallback = React.lazy(() => import('../../pages/Auth/AuthCallback'));

export default function NonAuthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/auth" element={<AuthenticateCallback />} />
      <Route path="*" element={<Authenticate />} />
    </Routes>
  );
}
