import {useLDClient} from 'launchdarkly-react-client-sdk';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Error500 from '../components/error/Error500';
import {
  preloadDeferredRoutes,
  preloadRouteByPathname,
  ROUTES,
} from '../utils/routePreloader';
import PageLoader from './layout/PageLoader';
import AllRoutes from './routes/AllRoutes';
import NonAuthenticatedRoutes from './routes/NonAuthenticatedRoutes';
import WarehouseNotConnectedRoutes from './routes/WarehouseNotConnectedRoutes';
import Analytics from './services/analytics/Analytics';
import {appEventsPA} from './services/analytics/appEvents';
import useAppStoreSelector from './store/useAppStoreSelector';

const EventPreview = React.lazy(ROUTES.EventPreview.import);

export default function MainContainer() {
  const state = useAppStoreSelector((s) => s.state);
  const {isLoading, user, err, integrationFetchError} = state;
  const navigate = useNavigate();

  const ldClient = useLDClient();

  useEffect(() => {
    if (user) {
      ldClient.identify({
        key: user?.email,
        uuid: user?.uuid,
        name: user?.name,
        email: user?.email,
        custom: {
          organization: user?.organization?.name,
          hasSemanticLayer: user?.organization?.has_dbt_semantic_layer,
        },
      });
      Analytics.page(appEventsPA.LOGIN_SUCCESS);
    }
  }, [user, ldClient]);

  useEffect(() => {
    const routerFunc = (newUrl) => navigate(newUrl);
    window.CommandBar.addRouter(routerFunc);
  }, [navigate]);

  useEffect(() => {
    preloadRouteByPathname(window.location.pathname);
    preloadDeferredRoutes();
  }, []);

  if (isLoading) {
    return (
      <PageLoader
        style={{width: '100vw', height: '100vh', background: 'var(--background)'}}
      />
    );
  }

  if (err || integrationFetchError) {
    const errStr = err || integrationFetchError;
    return <Error500 errorText={errStr} retryText="Reload" />;
  }

  if (!user) {
    return <NonAuthenticatedRoutes />;
  }

  if (!user?.integrations?.is_warehouse_connected) {
    return <WarehouseNotConnectedRoutes />;
  }

  return (
    <>
      <AllRoutes />
      <EventPreview />
    </>
  );
}
