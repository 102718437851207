import {ArrowRightOutlined, CopyOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@tanstack/react-query';
import {Button, Form, Input, Typography, message} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import config from '../../../app/config';
import AccountLayout from '../../../app/layout/AccountLayout/AccountLayout';
import useAppStoreSelector from '../../../app/store/useAppStoreSelector';
import {userSnowflakeSecureDataSharingScripts} from './SnowflakeSecureDataSharingScripts';

const {Text, Title, Link} = Typography;

const SnowflakeSecureDataSharing = () => {
  const state = useAppStoreSelector((s) => s.state);
  const navigate = useNavigate();
  const [isSnowflakeDetailsShared, setIsSnowflakeDetailsShared] = useState(false);
  const orgName = state.user?.organization?.name;

  const [copySuccess, setCopySuccess] = useState(false);
  const buttonStyles = {
    transition: 'all 0.3s ease-in-out',
    backgroundColor: copySuccess ? '#52c41a' : '',
    borderColor: copySuccess ? '#52c41a' : '',
    color: copySuccess ? '#fff' : '',
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(userSnowflakeSecureDataSharingScripts);
    setCopySuccess(true);
    message.success('Copied to clipboard', 2);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  const apiUrl = `https://api.airtable.com/v0/app4SSqhRp6GAzaNj/Snowflake%20Secure%20data%20Sharing`;
  const apiKey = config.airtableSecureDataSharingApiKey;
  const filterFormula = `AND({Organization} = "${orgName}")`;

  // Fetch record form airtable
  const {data: recordData, isLoading: isRecordLoading} = useQuery({
    queryKey: ['record', orgName],
    queryFn: async () => {
      const response = await fetch(
        `${apiUrl}?filterByFormula=${encodeURIComponent(filterFormula)}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Error fetching record');
      }
      return response.json();
    },
  });
  const [showDetailForm, setShowDetailForm] = useState(!recordData?.records.length);

  // Mutation for creating/updating record
  const mutation = useMutation({
    mutationFn: async (values: Record<string, string>) => {
      //check if record already exist in airtable
      const recordExists = recordData?.records.length === 1;

      const newFields = {
        'Share Name': values['share Name'],
        'Snowflake Organization Name': values['Snowflake Organization Name'],
        'Snowflake Account Name': values['Snowflake Account Name'],
      };

      const requestData = recordExists
        ? {
            method: 'PATCH',
            url: `${apiUrl}/${recordData.records[0].id}`,
            body: {
              fields: {...recordData.records[0].fields, ...newFields},
            },
          }
        : {
            method: 'POST',
            url: apiUrl,
            body: {
              fields: {Organization: orgName, ...newFields},
            },
          };

      const response = await fetch(requestData.url, {
        method: requestData.method,
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData.body),
      });

      if (!response.ok) {
        throw new Error('Error processing record in Airtable');
      }

      return response.json();
    },
  });

  const onFinish = async (values: Record<string, string>) => {
    try {
      await mutation.mutateAsync(values);
      setIsSnowflakeDetailsShared(true);
    } catch (error) {
      message.error('Something happened, please try again later.', 2);
    }
  };

  const isLoading = isRecordLoading || mutation.isPending;

  if (isSnowflakeDetailsShared) {
    return (
      <AccountLayout style={{justifyContent: 'center'}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 32,
            minHeight: 'calc(100vh - 180px)',
            padding: 40,
          }}
        >
          <Text style={{marginBottom: 24, textAlign: 'center', fontSize: '1.2rem'}}>
            We are currently working on connecting your warehouse… Meanwhile, <br /> check
            out the sneak peek, which takes you through a Workspace on LaunchDarkly. ✨
            <br />
            Get a taste of all that is in store for you post the connection. 🤩
          </Text>
          <div
            style={{
              background: '#fbfaff87',
              borderEndEndRadius: 8,
              borderTopRightRadius: 8,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50%',
              minHeight: 0,
              width: '100%',
            }}
          >
            <iframe
              title="LaunchDarkly | Product Analytics"
              src="https://demo.arcade.software/f9ESaNpeuW7K32OpfAKK?embed"
              allowFullScreen
              style={{
                height: '100%',
                width: '100%',
                border: 'none',
                boxShadow: '1px 1px 10px grey',
                borderRadius: '6px',
                colorScheme: 'light',
              }}
              seamless
              loading="lazy"
            ></iframe>
          </div>
          <Text style={{marginTop: '2rem'}}>
            You can also view our{' '}
            <Link
              href="https://docs.houseware.io/docs/building-blocks-of-houseware"
              target="_blank"
              underline
            >
              Product Quick Start guide
            </Link>
            , and other help documentation, to understand more about how to best build
            your analysis on LaunchDarkly!
          </Text>
          <Button
            type="primary"
            icon={<ArrowRightOutlined />}
            style={{marginTop: 24}}
            onClick={() => {
              navigate('/');
            }}
            href="https://docs.houseware.io/docs/building-blocks-of-houseware"
          >
            Go to docs
          </Button>
        </div>
      </AccountLayout>
    );
  }

  return (
    <AccountLayout style={{justifyContent: 'center'}}>
      <div>
        <div style={{margin: '8px 0px'}}>
          <Title level={4}>Connecting Snowflake secure data sharing</Title>
          <Text>
            Log in to your Snowflake data warehouse and run the below script to create a
            new share for LaunchDarkly. Note: You may need `ACCOUNTADMIN`` privileges to
            create new share.
          </Text>
        </div>
        <div
          style={{
            padding: 16,
            borderRadius: 4,
            background: 'rgba(27, 31, 35, 0.05)',
            color: '#4a4c4b',
            textAlign: 'left',
            fontFamily: 'monospace',
            fontSize: '.9rem',
            display: 'flex',
            flexDirection: 'column',
            margin: '32px 16px',
            width: 800,
          }}
        >
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button style={buttonStyles} onClick={handleCopy}>
              <CopyOutlined /> {copySuccess ? 'Copied!' : 'Copy'}
            </Button>
          </div>
          <pre>
            <Text>{userSnowflakeSecureDataSharingScripts}</Text>
          </pre>
        </div>
        {showDetailForm ? (
          <>
            <Text strong style={{marginTop: 12, fontSize: '1.2rem'}}>
              Almost there! Please provide the following details so that LaunchDarkly can
              connect to your Snowflake instance
            </Text>
            <Form
              name="snowflake_detials"
              labelCol={{span: 6}}
              wrapperCol={{
                span: 12,
              }}
              initialValues={{remember: true}}
              onFinish={onFinish}
              scrollToFirstError
              style={{width: 800, margin: '32px 16px'}}
            >
              <Form.Item
                label="Share Name"
                name="share Name"
                rules={[
                  {required: true, message: 'Please provide your Snowflake share name'},
                ]}
              >
                <Input placeholder="share name" />
              </Form.Item>
              <Form.Item
                label="Snowflake Organization Name"
                name="Snowflake Organization Name"
                rules={[
                  {
                    required: true,
                    message: 'Please provide your snowflake Organization Name',
                  },
                ]}
              >
                <Input placeholder="name of your snowflake organization" />
              </Form.Item>
              <Form.Item
                label="Snowflake Account Name"
                name="Snowflake Account Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input snowflake account name!',
                  },
                ]}
              >
                <Input placeholder="unique name of your account within your organization" />
              </Form.Item>
              <Form.Item
                wrapperCol={{span: 24}}
                style={{textAlign: 'center', marginTop: 32}}
              >
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Connect
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '1rem',
            }}
          >
            <Text>
              Kindly note:
              <br /> The Snowflake Secure Data Sharing form has already been completed
              from your end. You'll be notified when the LaunchDarkly instance is
              connected and live.
            </Text>
            <Text>
              <br /> Meanwhile if you'd like to make any changes to the details submitted,
              you can do so by clicking on the "Edit Form" button below.
            </Text>
            <Button type="primary" onClick={() => setShowDetailForm(true)}>
              Edit Form
            </Button>
          </div>
        )}
      </div>
    </AccountLayout>
  );
};

export default SnowflakeSecureDataSharing;
