import dayjs from 'dayjs';
import config from '../config';
import $fetch from '../services/$fetch';

// Block apis
const workspaceRootData = {
  key: 'root-workspaces',
  type: 'workspace',
  properties: {
    emoji: '🏠',
    title: 'Workspaces',
    linksTo: '/workspaces',
  },
  parent_block_uuid: null,
};
const notFoundPage = {
  key: 'page-not-found',
  type: 'page',
  properties: {
    emoji: '🏠',
    title: 'Page not found',
  },
  content: [
    {
      type: 'image',
      properties: {
        image_url: '/assets/error.png',
      },
    },
  ],
  parent_block_uuid: null,
};
const invalidUserAccess = {
  key: 'invalid-user-access',
  type: 'page',
  properties: {
    emoji: '🏠',
    title: 'Insufficient access level',
  },
  content: [
    {
      type: 'image',
      properties: {
        image_url: '/assets/403.png',
      },
    },
  ],
  parent_block_uuid: null,
};

export async function createBlockAPI(payload) {
  const res = await $fetch(`${config.apiHostURL}/organization/blocks`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  if (res && res.ok) {
    return res.json();
  }
  throw Error("Cloudn't update the block");
}
function getBlocksURLs(uuid) {
  if (uuid === 'workspaces') {
    return `${config.apiHostURL}/organization/blocks`;
  }

  if (uuid === 'visualizations') {
    return `${config.apiHostURL}/organization/blocks/visualizations`;
  }

  return `${config.apiHostURL}/organization/blocks/${uuid.toLowerCase()}`;
}

export async function fetchBlockData(uuid) {
  const url = getBlocksURLs(uuid);
  const res = await $fetch(url);

  if (res && res.ok) {
    const data = await res.json();

    if (uuid === 'workspaces') {
      const workspaces = [...(data?.workspaces || [])].map((block, index) => ({
        ...block,
        id: block.properties.sequence_id || index,
      }));

      workspaces.sort(
        (workspace1, workspace2) =>
          (workspace1.properties.sequence_id || 0) -
          (workspace2.properties.sequence_id || 0)
      );

      return {
        sharedWorkspaces: data?.shared_workspaces || [],
        ...workspaceRootData,
        content: workspaces,
        uuid,
      };
    }

    if (uuid === 'visualizations') {
      const blocks = [...(data?.visualizations || [])].map((block, index) => ({
        ...block,
        id: block.properties.sequence_id || index,
      }));

      blocks.sort(
        (block1, block2) =>
          dayjs(block2?.updated_at).valueOf() - dayjs(block1?.updated_at).valueOf()
      );

      return {
        content: blocks,
      };
    }

    const blockData = data.block;
    const childBlocks = (blockData.content || []).map((block, index) => ({
      ...block,
      id: block.properties.sequence_id || index,
    }));

    childBlocks.sort(
      (block1, block2) =>
        (block1.properties.sequence_id || 0) - (block2.properties.sequence_id || 0)
    );

    return {
      ...blockData,
      content: childBlocks,
    };
  }
  if (res.status === 403) {
    return invalidUserAccess;
  }

  return notFoundPage;
}

export async function updateBlockAPI({uuid, data, abortDataUpdate = false}) {
  const res = await $fetch(`${config.apiHostURL}/organization/blocks/${uuid}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
  throw Error("Cloudn't update the block");
}

export async function deleteBlockAPI(blockId) {
  return $fetch(`${config.apiHostURL}/organization/blocks/${blockId}`, {
    method: 'DELETE',
  });
}

export async function cloneBlockAPI({
  blockId,
  destinationParentUuid,
  overrideProperties,
}) {
  const url = new URL(`${config.apiHostURL}/organization/blocks/${blockId}/clone`);
  if (destinationParentUuid) {
    url.searchParams.set('destination_parent_uuid', destinationParentUuid);
  }
  const res = await $fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      override_properties: overrideProperties,
    }),
  });
  if (res.ok) {
    const result = await res.json();
    if (result.success) {
      if (!result.block_uuid) {
        result.block_uuid = result.block.uuid;
      }
      return result;
    }
  }
  throw Error("Couldn't clone the Workspace");
}

export async function getFavouriteAppAPI() {
  const res = await $fetch(`${config.apiHostURL}/user/favourites/blocks`);
  if (res.ok) {
    const results = await res.json();
    return results.blocks;
  }
  if (res.status === 404) {
    return [];
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function markFavouriteAPI(appId) {
  const res = await $fetch(`${config.apiHostURL}/user/favourites/blocks/${appId}`, {
    method: 'POST',
  });
  if (res.ok) {
    return res.json();
  }
  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function unMarkFavouriteAPI(appId) {
  await $fetch(`${config.apiHostURL}/user/favourites/blocks/${appId}`, {
    method: 'DELETE',
  });
}

export async function reArrangeBlocksAPI(payload) {
  const url = `${config.apiHostURL}/organization/blocks/rearrange`;
  return $fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}

export async function getRecentWorkspacesAPI() {
  const res = await $fetch(`${config.apiHostURL}/organization/blocks/recents`);
  if (res.ok) {
    const results = await res.json();
    return results.recent_blocks;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}
